
import { React } from 'react'
import "../CSS/NavBarComponent.css"
import logo from "../Images/logo-2.png"
import { NavLink,Link } from "react-router-dom"
const NavBarCompnent = () => {

    const navLinkStyle = ({ isActive }) => {
        return {
            color: isActive ? '#ff0101' : 'rgb(0 0 111)',
        }
    }



    return (
        <>
            <nav class="navbar navbar-expand-lg">
                <div className="container-fluid">
                    <Link to="/"><a className="navbar-brand" href="logo"><img src={logo} alt="logo" /></a></Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <a className="nav-link" href="home"><NavLink style={navLinkStyle} to="/">Home</NavLink></a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="destination"><NavLink style={navLinkStyle} to="/Tours">Tours</NavLink></a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="Adventures"><NavLink style={navLinkStyle} to="/Adventures">Adventures</NavLink></a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href='Hotels'><NavLink style={navLinkStyle} to="/Hotels">Hotels</NavLink></a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href='About'><NavLink style={navLinkStyle} to="/About">About Us</NavLink></a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href='Contact'><NavLink style={navLinkStyle} to="/Contact">Contact Us</NavLink></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default NavBarCompnent