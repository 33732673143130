
// react revel 

import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import Flip from 'react-reveal/Flip';

import Fimage1 from "../Images/sign-footer.svg"
import Fimage2 from "../Images/Footer-trekking.svg"
import Fimage3 from "../Images/around-Footer.svg"

import React from 'react'
import "../CSS/HomePage.css"
import FooterComponent from '../Component/FooterComponent'
import { Link } from 'react-router-dom';

// SECTION2 

import Slogo from "../Images/Home-section-2.png"
import Chitvan from "../Images/Popular Destination/Chitwan.jpg"

import Pokhara from "../Images/Popular Destination/Pokhara.JPG"
import Kathmandu from "../Images/Popular Destination/Kathmandu.jpg"
import Nagarkot from "../Images/Nagarkot.jpg"
import Muktinath from "../Images/Temple.jpg"
// import Simage3 from "../Images/_DSC3323.jpg"


//////////////////////////////////// gallary ////////////////////////////////////////
import BungeeJumping from "../Images/gallary-Bungee Jumping.jpeg"
import Trekking from "../Images/gallary-Trekking.jpg";
import Helicopter from "../Images/gallary-Helicopter.JPG";
import elephant_safari from "../Images/gallary-elephant_safari.jpg";
import paragliding from "../Images/gallary-paragliding.jpg";
import RiverRafting from "../Images/gallary-River Rafting.jpg"
import ultralightflight from "../Images/gallary-ultralight flight.jpg";
import gallary_zip_flyer from "../Images/gallary-zip-flyer.jpg";


// Swiper

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import "swiper/css/pagination";


// import required modules
import { Navigation, Pagination, Autoplay } from "swiper";

// Hotel Images
import HotelImage01 from "../Images/Hotel Jungle Crown Resort.jpg";
import HotelImage02 from "../Images/_DSC3323.jpg";
import HotelImage03 from "../Images/Hotel Temple Tree.jpg";
import HotelImage04 from "../Images/Hotel Atithi Resort.jpg";
import HotelImage05 from "../Images/Hotel Barahi Jungle Lodge.jpg";
import HotelImage06 from "../Images/Hotel Center Park Resort.jpg";
import HotelImage07 from "../Images/Hotel Mulberry.jpg";
import HotelImage08 from "../Images/Hotel Barahi.jpg";
import HotelImage09 from "../Images/Hotel Radisson.jpg";

// Award Images
import Award01 from "../Images/Award01.jpg"
import Award02 from "../Images/Award02.jpg"
import Award03 from "../Images/Award03.jpg"
import Award04 from "../Images/Award04.jpg"
import Award05 from "../Images/Award05.jpg"


import mapWithName from "../Images/NEPAL TOURISM.png"

// social media icon 

import { GrFacebookOption } from 'react-icons/gr';
import { AiFillInstagram } from 'react-icons/ai';
import { IoLogoYoutube } from 'react-icons/io';

import CImage01 from "../Images/CImage01.jpg";
import CImage02 from "../Images/CImage02.jpg";
import CImage03 from "../Images/CImage03.jpg";
import CImage04 from "../Images/CImage04.jpg";

// Reviews 
import ReviewsSwaiper from './ReviewsSwaiper';

const HomePage = () => {
  const CImagestyle = {
    backgroundColor: "#ededed",
    boxShadow: "0 0 5px #808080",
    borderRadius: "5px",
    height: "100%",
  }
  return (
    <>
      {/* //////////////////////////////////////////// SECTION 1 /////////////////////////////////////////// */}

      <section className='Home-banner'>
        <div className='Home-banner-Image'>
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
          >
            <SwiperSlide className="HomeBannerSwiper1"></SwiperSlide>
            <SwiperSlide className="HomeBannerSwiper2"></SwiperSlide>
            <SwiperSlide className="HomeBannerSwiper3"></SwiperSlide>
          </Swiper>
        </div>
      </section>

      {/* //////////////////////////////////////////// SECTION 2 /////////////////////////////////////////// */}

      <section className="destinationSection" id="destination">
        <div className="container">
          <div className="titleSection">
            <img src={Slogo} alt="" />
            <Fade left>
              <h2>Trip Ideas this year</h2>
            </Fade>
            <Zoom>
              <p>We are the one-stop solution for all your travel-related requirements. For both personal and business travel we can make your dreams come true by putting together a complete package that includes everything you need to execute your travel plans.</p>
            </Zoom>
          </div>
          <div className="row">

            <div className="col-md-4">
              <div className="row">
                <div className="col-md-12 col-sm-6 col-12 mb-3">
                  <Link to={`/Tours`}>
                    <div className="categryBanner" style={{ height: "250px", margin: "auto", background: "url(" + Chitvan + ")", backgroundSize: "100% 100%", webkitBackgroundSize: "cover" }}>
                      <Flip left cascade>
                        <h3>Chitwan</h3>
                      </Flip>
                    </div>
                  </Link>
                </div>

                <div className="col-md-12 col-sm-6 col-12 mb-3">
                  <Link to={`/Tours`}>
                    <div className="categryBanner" style={{ height: "250px", margin: "auto", background: "url(" + Pokhara + ")", backgroundSize: "100% 100%" }}>
                      <Flip left cascade>
                        <h3>Pokhara</h3>
                      </Flip>
                    </div>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-md-4 d-flex justify-content-center align-content-center mb-3 mt-3">
              <div className='mid-image'>
                <Link to={`/Tours`}>
                  <div className="categryBanner" style={{ margin: "auto", height: "400px", background: " url(" + Kathmandu + ")", backgroundSize: "100% 100%" }}>
                    <Flip left cascade>
                      <h3>Kathmandu</h3>
                    </Flip>
                  </div>
                </Link>
              </div>
            </div>

            <div className="col-md-4">
              <div className="row">
                <div className="col-md-12 col-sm-6 col-12 mb-3">
                  <Link to={`/Tours`}>
                    <div className="categryBanner" style={{ height: "250px", margin: 'auto', background: "url(" + Nagarkot + ")", backgroundSize: "100% 100%" }}>
                      <Flip left cascade>
                        <h3>Nagarkot</h3>
                      </Flip>
                    </div>
                  </Link>
                </div>

                <div className="col-md-12 col-sm-6 col-12">
                  <Link to={`/Tours`}>
                    <div className="categryBanner" style={{ height: "250px", margin: "auto", background: "url(" + Muktinath + ")", backgroundSize: "100% 100%" }}>
                      <Flip left cascade>
                        <h3>Muktinath</h3>
                      </Flip>
                    </div>
                  </Link>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>

      {/* //////////////////////////////////////////// SECTION 3 /////////////////////////////////////////// */}

      <section className="aboutSection">
        <div className="container">
          <div className="row">

            {/* <div className='col-md-6'>
              <div className='Homepage-Company_Image'>
                <div className='row' style={CImagestyle}>
                  <div className='col-6' style={{padding:"3px"}}>
                    <div className='CImage'>
                      <img src={CImage01} alt="" />
                    </div>
                  </div>
                  <div className='col-6' style={{padding:"3px"}}>
                    <div className='CImage'>
                      <img src={CImage02} alt="" />
                    </div>
                  </div>
                  <div className='col-6' style={{padding:"3px"}}>
                    <div className='CImage'>
                      <img src={CImage03} alt="" />
                    </div>
                  </div>
                  <div className='col-6' style={{padding:"3px"}}>
                    <div className='CImage'>
                      <img src={CImage04} alt="" />
                    </div>
                  </div>

                </div>
              </div>
            </div> */}

            <div className="col-12">
              <div className='AboutCompany'>
                <div className="aboutText">
                  <p>Vision Holidays was founded in year 1995 in Mumbai by travel enthusiast and entrepreneur Mr.Manoj Dubey. We are proud to be India’s first choice for customisable holiday itineraries to Nepal. We take pride in being the preferred sales agency for Nepal Airlines & Nepal Tourism Board as we work directly with them to make your dream Nepal Holiday come true.</p>
                  <p>We dedicatedly ensure that every step of your journey, down to the last detail, is taken care of by us so you can enjoy your holidays and make memories with your loved ones. As veterans in the industry, we understand how tedious vacation planning can be, this is where our expertise steps in as we create, execute and support you throughout your journey. We assure you comfortable  stays, hassle free travel and beautiful sight seeing among taking complete care of various other aspects of your journey. Not to forget we walk with you every step of the way. </p>
                </div>
                <div className="aboutButton">
                  <a href="/About"> <button className="btn">View More</button></a>
                </div>
              </div>
            </div>

          </div>
          <div className='Award-row'>
            <div className='row row-cols-lg-5 row-cols-md-3 row-cols-sm-2 row-cols-1'>
              <div className='col'>
                <div className='Award'>
                  <div className='Award-Images'>
                    <img src={Award01} alt='' />
                  </div>
                </div>
              </div>
              <div className='col'>
                <div className='Award'>
                  <div className='Award-Images'>
                    <img src={Award02} alt='' />
                  </div>
                </div>
              </div>
              <div className='col'>
                <div className='Award'>
                  <div className='Award-Images'>
                    <img src={Award03} alt='' />
                  </div>
                </div>
              </div>
              <div className='col'>
                <div className='Award'>
                  <div className='Award-Images'>
                    <img src={Award04} alt='' />
                  </div>
                </div>
              </div>
              <div className='col'>
                <div className='Award'>
                  <div className='Award-Images'>
                    <img src={Award05} alt='' />
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>

      {/* //////////////////////////////////////////// SECTION 4 /////////////////////////////////////////// */}

      <section className="enquiryBanner">
        <div className="container">
          {/* <div className='SlugLogo'>
            <img src={Nlogo} alt="" />
          </div> */}
          <div className="enquiryText">
            <img src={mapWithName} alt="" />
          </div>
        </div>
      </section>




      {/* //////////////////////////////////////////// SECTION 3 /////////////////////////////////////////// */}
      {/* <!-- =============================== Number Of Clients ========================= --> */}

      <section className="numberClients" id="numberClientsCounts">
        <div className="container">
          <div className="titleSection">
            <img src={Slogo} alt="" />
            <Fade left>
              <h2>Our Progressive Work</h2>
            </Fade>
            <Zoom>
              <p>We are the one-stop solution for all your travel-related requirements. For both personal and business travel we can make your dreams come true by putting together a complete package that includes everything you need to execute your travel plans.</p>
            </Zoom>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-6 mb-4 d-flex justify-content-center align-content-center ">
              <div className="cardHover">
                <span className="t_over"></span>
                <span className="t_over"></span>
                <span className="t_over"></span>
                <span className="t_over"></span>
                <span className="t_over"></span>
                <span className="t_over"></span>
                <span className="t_over"></span>
                <span className="t_over"></span>
                <span className="t_over"></span>
                <div className='clientCard'>
                  <div className="card-content">
                    <div className="shadowEffect">
                      <h1><span className="count" data-count="10">27</span> <span className="shadow"></span></h1>
                    </div>
                    <p>Years Experiance</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 mb-4 d-flex justify-content-center align-content-center ">
              <div className="cardHover">
                <span className="t_over"></span>
                <span className="t_over"></span>
                <span className="t_over"></span>
                <span className="t_over"></span>
                <span className="t_over"></span>
                <span className="t_over"></span>
                <span className="t_over"></span>
                <span className="t_over"></span>
                <span className="t_over"></span>
                <div className='clientCard'>
                  <div className="card-content">
                    <div className="shadowEffect">
                      <h1><span className="count" data-count="300">8100</span> <span className="shadow"></span></h1>
                    </div>
                    <p>Completed Tour</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 mb-4 d-flex justify-content-center align-content-center ">
              <div className="cardHover">
                <span className="t_over"></span>
                <span className="t_over"></span>
                <span className="t_over"></span>
                <span className="t_over"></span>
                <span className="t_over"></span>
                <span className="t_over"></span>
                <span className="t_over"></span>
                <span className="t_over"></span>
                <span className="t_over"></span>
                <div className='clientCard'>
                  <div className="card-content">
                    <div className="shadowEffect">
                      <h1><span className="count" data-count="10000">24800</span> <span className="shadow"></span></h1>
                    </div>
                    <p>Happy Tourist</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>



      {/* <!-- ========================================= gallery Section ====================================  --> */}

      <section id="gallery">
        <div className="container">
          <div id="image-gallery">
            <div className="row">

              <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-3 image ">
                <div className="img-wrapper">
                  <img src={BungeeJumping} alt="" />
                  <div className="img-overlay">
                    <Zoom left cascade>
                      <h4>Bungee Jumping</h4>
                    </Zoom>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4 image">
                <div className="img-wrapper">
                  <img src={Trekking} alt="" />
                  <div className="img-overlay">
                    <Zoom left cascade>
                      <h4>Trekking</h4>
                    </Zoom>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4 image">
                <div className="img-wrapper">
                  <img src={RiverRafting} alt="" />
                  <div className="img-overlay">
                    <Zoom left cascade>
                      <h4>River Rafting</h4>
                    </Zoom>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4 image">
                <div className="img-wrapper">
                  <img src={elephant_safari} alt="" />
                  <div className="img-overlay">
                    <Zoom left cascade>
                      <h4>Elephant Safari</h4>
                    </Zoom>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4 image">
                <div className="img-wrapper">
                  <img src={paragliding} alt="" />
                  <div className="img-overlay">
                    <Zoom left cascade>
                      <h4>Paragliding</h4>
                    </Zoom>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4 image">
                <div className="img-wrapper">
                  <img src={Helicopter} alt="" />
                  <div className="img-overlay">
                    <Zoom left cascade>
                      <h4>Helicopter</h4>
                    </Zoom>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4 image">
                <div className="img-wrapper">
                  <img src={ultralightflight} alt="" />
                  <div className="img-overlay">
                    <Zoom left cascade>
                      <h4>Ultralight Flight</h4>
                    </Zoom>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4 image">
                <div className="img-wrapper">
                  <img src={gallary_zip_flyer} alt="" />
                  <div className="img-overlay">
                    <Zoom left cascade>
                      <h4>Zip Flyer</h4>
                    </Zoom>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4 image">
                <div className="img-wrapper">
                  <img src={RiverRafting} alt="" />
                  <div className="img-overlay">
                    <Zoom left cascade>
                      <h4>River Rafting</h4>
                    </Zoom>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      {/* <!-- ====================================== Social meadia ======================================= --> */}

      <section className="ourSocialLink">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 d-flex justify-content-start align-items-end">
              <div className="socialText">
                <h2>follow Us</h2>
                <p>Know more about us</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 d-flex justify-content-end align-items-end">
              <ul className="socialItems">
                <li><a href="https://www.facebook.com/nepaltours888" target="_blank"><GrFacebookOption /></a></li>
                <li><a href="https://www.instagram.com/nepaltours888/" target="_blank"><AiFillInstagram /></a></li>
                <li><a href="https://www.youtube.com/channel/UCpn1ZZmk5PP0-kRxwj5EGWQ" target="_blank"><IoLogoYoutube /></a></li>
              </ul>
            </div>
          </div>
        </div>
      </section>


      {/* ///////////////////////////////////////////  hotels list //////////////////////////////////////////// */}

      <section className='Hotel-Section'>
        <div className="container">
          <div className='Hotel-List'>
            <h3>OUR PREFER HOTELS</h3>
            <hr></hr>

            <div className="Hotel-Gallery">
              <div className="row">

                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-3">
                  <div className="Hotel-image-wrapper">
                    <img src={HotelImage09} alt="Hotel Radisson" />
                    <div className="Hotel-img-overlay">
                      <h3>Hotel Radisson</h3>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-3">
                  <div className="Hotel-image-wrapper">
                    <img src={HotelImage07} alt="Hotel Mulberry" />
                    <div className="Hotel-img-overlay">
                      <h3>Hotel Mulberry</h3>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-3">
                  <div className="Hotel-image-wrapper">
                    <img src={HotelImage03} alt="Hotel Temple Tree" />
                    <div className="Hotel-img-overlay">
                      <h3>Hotel Temple Tree</h3>
                    </div>
                  </div>
                </div>


                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-3">
                  <div className="Hotel-image-wrapper">
                    <img src={HotelImage04} alt="Hotel Atithi Resort" />
                    <div className="Hotel-img-overlay">
                      <h3>Hotel Atithi Resort</h3>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-3">
                  <div className="Hotel-image-wrapper">
                    <img src={HotelImage02} alt="Country Villa" />
                    <div className="Hotel-img-overlay">
                      <h3>Country Villa</h3>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-3">
                  <div className="Hotel-image-wrapper">
                    <img src={HotelImage08} alt="Hotel Barahi" />
                    <div className="Hotel-img-overlay">
                      <h3>Hotel Barahi</h3>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-3">
                  <div className="Hotel-image-wrapper">
                    <img src={HotelImage01} alt="Hotel Jungle Crown Resort" />
                    <div className="Hotel-img-overlay">
                      <h3>Hotel Jungle Crown Resort</h3>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-3">
                  <div className="Hotel-image-wrapper">
                    <img src={HotelImage05} alt="Hotel Barahi Jungle Lodge" />
                    <div className="Hotel-img-overlay">
                      <h3>Hotel Barahi Jungle Lodge</h3>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-3">
                  <div className="Hotel-image-wrapper">
                    <img src={HotelImage06} alt="Hotel Center Park Resort" />
                    <div className="Hotel-img-overlay">
                      <h3>Hotel Center Park Resort</h3>
                    </div>
                  </div>
                </div>
              </div>


            </div>
            <hr></hr>
          </div>
        </div>
      </section>


      {/* ///////////////////////////////////////////  review dection //////////////////////////////////////////// */}

      <section className="cutomerReview" id="cutomerReview">
        <ReviewsSwaiper />

        <div className='Homepage-Company_Image'>
          <div className='row'>
            <div className='col-md-3 col-sm-6'>
              <div className='C-Images'>
              <img src={CImage01} alt="" />
              </div>
            </div>
            <div className='col-md-3 col-sm-6'>
              <div className='C-Images'>
              <img src={CImage02} alt="" />
              </div>
            </div>
            <div className='col-md-3 col-sm-6'>
              <div className='C-Images'>
              <img src={CImage03} alt="" />
              </div>
            </div>
            <div className='col-md-3 col-sm-6'>
              <div className='C-Images'>
              <img src={CImage04} alt="" />
              </div>
            </div>

          </div>
        </div>
      </section>

      {/* //////////////////////////////////////////////////////// section details ////////////////////////////////////////////////////// */}
      <section className='Extra-section'>
        <div className='Extra-1'>
          <div className='container'>
            <div className='Extra-quotes'>
              <h3>“Travel makes one modest, you see what a tiny place you occupy in the world.”</h3>
            </div>
            <div className='row'>
              <div className='col-md-4 col-sm-12'>
                <div className='Extradetails'>
                  <div className='ExtraImages'>
                    <img src={Fimage1} alt="" />
                  </div>
                  <div className='ExtraImages-Details'>
                    <h3>GIVE US A CALL</h3>
                    <p>Contact No : 9820 41 41 51</p>

                  </div>
                </div>
              </div>
              <div className='col-md-4 col-sm-12'>
                <div className='Extradetails'>
                  <div className='ExtraImages'>
                    <img src={Fimage2} alt="" />
                  </div>
                  <div className='ExtraImages-Details'>
                    <h3>COME & DROP BY</h3>
                    <p>Jewel Shopping Centre, Opp Seven Bunglow Garden.Andheri ( West ) Mumbai 400061</p>
                  </div>
                </div>
              </div>
              <div className='col-md-4 col-sm-12'>
                <div className='Extradetails'>
                  <div className='ExtraImages'>
                    <img src={Fimage3} alt="" />
                  </div>
                  <div className='ExtraImages-Details'>
                    <h3>SEND US A MESSAGE</h3>
                    <p> info@nepaltour.in</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <FooterComponent />
    </>
  )
}

export default HomePage;