import React from 'react'
import "../CSS/ReviewsSwaiper.css"
import ReviewsComponent from '../Component/ReviewsComponent';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
import { Keyboard, Scrollbar, Autoplay } from "swiper";

import { AiFillStar } from 'react-icons/ai';
import { BsFillEmojiSmileFill } from 'react-icons/bs';



const ReviewsSwaiper = () => {
    return (
        <>
            {/* <div className='container'> */}
            <div className='Rtitle'>
                <h4>What our Customers Say</h4>
            </div>
            <Swiper
                slidesPerView={1}
                centeredSlides={false}
                slidesPerGroupSkip={1}  
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                grabCursor={true}
                keyboard={{
                    enabled: true,
                }}
                breakpoints={{
                    769: {
                        slidesPerView: 2,
                        slidesPerGroup: 2,
                    },
                }}
                scrollbar={true}
                navigation={true}
                pagination={{
                    clickable: true,
                }}
                modules={[Autoplay, Keyboard, Scrollbar, Navigation, Pagination]}
                className="mySwiper"
            >

                {
                    ReviewsComponent.map((elem) => {
                        const { Name, Description, ShortDescription, Image } = elem;
                        return (
                            <>
                                <SwiperSlide>
                                    <div className='Reviews-Container'>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <div className='ReviewsDescription'>
                                                    <div className='star'>
                                                    <h5>{Name}</h5>
                                                    <h6><AiFillStar /> <AiFillStar /> <AiFillStar /> <AiFillStar /> <AiFillStar /> <BsFillEmojiSmileFill/></h6>
                                                    </div>
                                                    <p>
                                                        {ShortDescription}
                                                        {Description}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </SwiperSlide>
                            </>
                        )
                    })
                }
            </Swiper>
            {/* </div> */}
        </>
    )
}

export default ReviewsSwaiper;