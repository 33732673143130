import React from 'react'
import "../CSS/ToursInfo.css"

/////////////// Acordion ////////////////////
import images01 from "../Images/KPM-I-F-Image.jpg";
import images02 from "../Images/KPM-I-S-Image.jpg";
import images03 from "../Images/KPM-I-T-Image.jpg";
import FooterComponent from './FooterComponent';

////////////// Form ///////////////////////
import ItineraryForm from './ItineraryForm';


const KPMItinerary = () => {

    return (
        <>

            {/* ////////////////////////////// section 1 //////////////////////////////// */}

            <section className='ToursInfo-Section'>
                <div className='ToursInfo-banner-image'>
                </div>
            </section>

            {/* //////////////////////////////// section 2 //////////////////////////// */}

            <section className='ToursInfo-Section2'>
                <div className='container'>
                    <div className='ToursInfo-TourName'>
                        <h2> Kathmandu +  Pokhara +  Jomsom ( Muktintah )</h2>
                        <h4>7 Nights / 8 Days</h4>
                    </div>
                </div>
            </section>

            {/* //////////////////////////////// section 3 //////////////////////////// */}

            <section className='ToursInfo-Section2'>
                <div className='container'>
                    <div className='ToursInfo-Section3-Itinerary'>
                        <div className='row'>

                            <div className='col-md-12 col-sm-12'>
                                <div className='Itinerary-div'>
                                    <div class="accordion" id="accordionExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingOne">
                                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    Day 01 – Kathmandu Arrival
                                                </button>
                                            </h2>
                                            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>Meet at Kathmandu Airport and Transfer to Hotel. Check in at hotel. Over Night Stay in Kathmandu.</p>
                                                    <p>(Optional – Nepali Cultural Show with Dinner)</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingTwo">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    Day 02 – Kathmandu Sightseeing
                                                </button>
                                            </h2>
                                            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>After Breakfast At 10 Visit - Pashupatinath / Boudhanath / Swyambhunath / Kathmandu Durbar.
                                                    Over Night Stay in Kathmandu</p>
                                                    <p>(Optional – Mt Everest Flight – 6:30 AM)</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingThree">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    Day 03 – Kathmandu – Pokhara ( 200 KM – 6 Hours )
                                                </button>
                                            </h2>
                                            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>After Breakfast 10 AM Proceed For Pokhara. Enjoy Scenic Drive Along With River Trishuli.
                                                    Arrive Pokhara And Check, Overnight Stay In Pokhara</p>
                                                    <p>(Optional – Manokamana Cable Car / River Rafting)</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingFour">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                    Day 04 – Pokhara - Jomsom ( AIR )
                                                </button>
                                            </h2>
                                            <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour " data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>After Early Breakfast Transfer to Pokhara Domestic Airport For Josmsom Flight. Arrive Jomsom
                                                    and Proceed to Coach Station on Foot for Muktinath Temple by Sharing Coach.
                                                    Back to Hotel and Overnight Stay in Jomsom</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingFive">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                    Day 05 – Jomsom
                                                </button>
                                            </h2>
                                            <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>After Breakfast Day Free in Jomsom to Explore Old Marpha Village and Lake . Overnight Stay In Jomsom</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingSix">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                    Day 06 – Jomsom – Pokhara ( AIR )
                                                </button>
                                            </h2>
                                            <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>Early Breakfast Proceed to Jomsom Airport For Pokhara Flight. Arrive Pokhara Airport and Transfer to Pokhara Hotel   
                                                    After Lunch Pokhara Sightseeing Davis Fall / Gupteswar Cave / Vindhavasini / Seti Gorge
                                                    Over Night Stay in Pokhara</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingSeven">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                                    Day 07 – Pokhara – Kathmandu ( 200 KM – 6 Hours )
                                                </button>
                                            </h2>
                                            <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>After Breakfast 10 AM Transfer to Kathmandu. Arrive Kathmandu and Check in at Hotel.
                                                    Evening Free at Leisure. Overnight Stay in Kathmandu</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingEight">
                                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                                    Day 08 : Kathmandu – Departure
                                                </button>
                                            </h2>
                                            <div id="collapseEight" class="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <p>After Breakfast Transfer To Kathmandu International Airport</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {   /* ////////////////////////////////////////////////////// section 4 ///////////////////////////////////////////////////////// */}

            <section className='ToursInfo-Section4'>
                <div className='container'>
                    <div className='Offer'>
                        <div className='startAt'>
                            <div className='Start'>
                                <p>Package Start @</p>
                                <h5>Rs 41,000/- + 5% GST</h5>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-8'>
                            <div className='ToursInfo-Section4-Data'>
                                <div className='Cost-Includes'>
                                    <h4>Includes :</h4>
                                    <hr></hr>
                                    <ul className='list_ok'>
                                        <li>03 Nights Stay in Kathmandu with Breakfast</li>
                                        <li>02 Nights Stay in Pokhara with Breakfast</li>
                                        <li>02 Nights Stay in Jomsom with Breakfast</li>
                                        <li>Two International Airport Transfer in Kathmandu by Pvt Air Condition Vehicle</li>
                                        <li>Kathmandu Sightseeing by Pvt Air Condition Vehicle</li>
                                        <li>Kathmandu to Pokhara Transfer by Pvt Air Condition Vehicle</li>
                                        <li>Pokhara Sightseeing by Pvt Air Condition Vehicle</li>
                                        <li>Pokhara to Jomsom AIR</li>
                                        <li>Jomsom to Pokhara AIR</li>
                                        <li>Jomsom - Muktinth – Jomsom by Sharing Coach</li>
                                        <li>Pokhara to Kathmandu Transfer by Pvt Air Condition Vehicle</li>
                                        <li>Jomsom Registration / Permit ( 1 Photo + Passport Copy )</li>
                                        <li>All Applicable Hotel Tax 24.3% Included</li>
                                    </ul>
                                </div>

                                <div className='Sightseeing'>
                                    <h4>Kathmandu Sightseeing :</h4>
                                    <hr></hr>
                                    <ul>
                                        <li>
                                            Pasupatinath Temple / Bouddhanath Stupa / Swyambhunath Stupa / Kathmandu Durbar
                                        </li>
                                    </ul>
                                </div>

                                <div className='Sightseeing'>
                                    <h4>Pokhara Sightseeing :</h4>
                                    <hr></hr>
                                    <ul>
                                        <li>
                                            Sarangkot Sunrise Tour / Davis Fall / Gupteshwar Mahadev Cave / Vindhavasini Temple / Seti Gorge
                                        </li>
                                    </ul>
                                </div>

                                <div className='Sightseeing'>
                                    <h4>Jomsom :</h4>
                                    <hr></hr>
                                    <ul>
                                        <li>
                                            Muktintah Temple
                                        </li>
                                    </ul>
                                </div>

                                <div className='Sightseeing'>
                                    <h4>Important Jomsom Muktinath :</h4>
                                    <hr></hr>
                                    <ul>
                                        <li>
                                            Jomsom is Extreme Remote Village
                                        </li>
                                        <li>
                                            Jomsom Hotel Opposite Jomsom Airport. Passenger Have to Walk
                                        </li>
                                        <li>
                                            Jomsom Hotel to Coach Station Walking Distance, Cross the River Bridge
                                        </li>
                                        <li>
                                            Jomsom Flight Allowed 10 KG
                                        </li>
                                        <li>
                                            Jomsom Flight Operate Early Morning and Subject to Weather Condition
                                        </li>
                                    </ul>
                                </div>

                                <div className='Sightseeing'>
                                    <h4>Exclude –</h4>
                                    <hr></hr>
                                    <ul>
                                        <li>
                                            Entrance Fees During Sightseeing / Tour Guide / Air Ticket
                                        </li>
                                    </ul>
                                </div>

                                <div className='Note'>
                                    <h4>Note :</h4>
                                    <hr></hr>
                                    <ul>
                                        <li>
                                            Entrance Fees During Sightseeing / Tour Guide / Air Ticket
                                        </li>
                                        <li>
                                            Prices/Taxes are subject to changes and availability.
                                        </li>
                                        <li>
                                            Confirmed bookings will attract a cancellation fee
                                        </li>
                                    </ul>
                                </div>

                                <div className='Important'>
                                    <h4>Important :-</h4>
                                    <hr></hr>
                                    <ul>
                                        <li>
                                            All Passenger Must Carry Valid Indian Passport OR Election Card
                                        </li>
                                        <li>
                                            Vehicle Not At Disposal. All Transfer &amp; Sightseeing On Point To Point Basis
                                        </li>
                                        <li>
                                            Rates Valid For Indian Passport Holder Only.
                                        </li>
                                        <li>
                                            Any Additional Expenses Incurred Due to Flight Cancel/Delay/Natural Disaster/Political Action
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <ItineraryForm />

                            <div className='Side-Images'>

                                <div className='Side-Images01'>
                                    <img src={images01} alt="" />
                                </div>
                                <div className='Side-Images02'>
                                    <img src={images02} alt="" />
                                </div>
                                <div className='Side-Images03'>
                                    <img src={images03} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <FooterComponent />


        </>
    )
}

export default KPMItinerary;