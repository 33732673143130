
import React from 'react'
import "../CSS/AboutUsPage.css"
import FooterComponent from '../Component/FooterComponent'

// Manoj Dubey Image
import ManojDubey from "../Images/Manoj Dubey.jpg"

// Company Images
import CompanyImages01 from "../Images/Nepal Tours Company_01.jpg";
import CompanyImages02 from "../Images/Nepal Tours Company_02.jpg";


const AboutUsPage = () => {

  const ImageStyle = { borderTop: '2px solid rgb(218, 218, 218)', borderRight: '2px solid rgb(218, 218, 218)', borderTopRightRadius: "5px" }
  const OwnerDetailsStyle = { borderBottom: '2px solid rgb(218, 218, 218)' }
  return (
    <>

      {/* ////////////////////////////////////////////// Section 1 ////////////////////////////////////////////// */}

      <section className='AboutUs-Section'>
        <div className='AboutUs-banner-Image'>
          <div className='AboutUs-Banner-text'>
          </div>
        </div>
      </section>

      {/* /////////////////////////////////////////// Section 2 /////////////////////////////////////////// */}

      <section className='AboutUs-Section-2'>
        <div className='container'>

          <div className='About-Section-2-info'>
            <div className='AboutUs-Section-2-title'>
              <h5>Amazing tours. Great deals. <strong>Nepal Tour</strong> tour packages</h5>
              <p>
                Nepal tour packages are the best way to get no about Nepal culture and history.
                If you want to discover the beautiful Emirates, then book now your tour packages.
                We provide exclusive vacation packages for family individual travels, honeymooners or business travelers.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* /////////////////////////////////////////// Section 3 /////////////////////////////////////////// */}


      <section className='AboutUs-Section-3'>
        <div className='container'>
          <div className='Owner-About'>
            <div className='row'>
              <div className='col-lg-4 col-md-12 col-sm-12' style={ImageStyle}>
                <div className='About-Owner-Image'>
                  <img src={ManojDubey} alt="" />
                </div>
              </div>
              <div className='col-lg-8 col-md-12 col-sm-12' style={OwnerDetailsStyle}>
                <div className='Owner-Details'>
                  <h4>Manoj Dubey</h4>
                  <hr></hr>
                  <p>Manoj Dubey has been working in the tourism industry since the last two decades. He started working as a Tour Leader with Nepal Travel Information Centre, a Kathmandu based leading travel company which gave him unmatchable destination exposure over the years.</p>
                  <p>In 1995, inspired by his own travels to the land of Nepal, he founded his travel boutique by the name of Vision Holidays. Headquartered at Mumbai, this company has scaled to great heights and has emerged as the most traveller friendly holiday boutique for Indians planning to explore Nepal like never before. Vision Holidays is also the preferred sales agency in India for Nepal Airlines & Nepal Tourism Board under their agreement of joint promotion in the Indian market.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      {/* /////////////////////////////////////////// Section 4 /////////////////////////////////////////// */}


      <section className='AboutUs-Section-4'>

        <div className='container'>
          <div className='Company-About-title'>
            <h3>About Company</h3>
          </div>
          <div className='Company-About'>
            <div className='row'>
              <div className='col-lg-6 col-md-12 col-sm-12'>
                <div className='Company-Details'>
                  <div className='Company-Info'>
                    <img src={CompanyImages01} alt='' />
                  </div>

                </div>

              </div>
              <div className='col-lg-6 col-md-12 col-sm-12'>
                <div className='Company-Details'>
                  <div className='Company-Info'>
                    <img src={CompanyImages02} alt='' />

                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='VisionMission'>
            <div className='row'>
              <div className='col-md-6 col-sm-12'>
                <div className='OurVision'>
                  <p>Vision Holidays was founded in year 1995 in Mumbai by travel enthusiast and entrepreneur Mr.Manoj Dubey. We are proud to be India’s first choice for customisable holiday itineraries to Nepal. We take pride in being the preferred sales agency for Nepal Airlines & Nepal Tourism Board as we work directly with them to make your dream Nepal Holiday come true.</p>
                  <p>We dedicatedly ensure that every step of your journey, down to the last detail, is taken care of by us so you can enjoy your holidays and make memories with your loved ones. As veterans in the industry, we understand how tedious vacation planning can be, this is where our expertise steps in as we create, execute and support you throughout your journey. We assure you comfortable  stays, hassle free travel and beautiful sight seeing among taking complete care of various other aspects of your journey. Not to forget we walk with you every step of the way. </p>
                </div>
              </div>
              <div className='col-md-6 col-sm-12'>
                <div className='OurMission'>
                  <h3>Our Mission</h3>
                  <p>To provide you with unmatched holiday experiences accompanied with best in class services and true value for your money, time and trust in us. With our deeply rooted ideology of reliability, dedication and customer satisfaction, we aim to make your holidays bigger, better and unforgettable.</p>
                  <hr></hr>
                  <h3>Our Vision</h3>
                  <p>We create the journey of a lifetime, every time.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>



      <FooterComponent />


    </>
  )
}

export default AboutUsPage