
import './App.css';
import NavBarCompnent from './Component/NavBarCompnent';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import HomePage from './Pages/HomePage';
import DestinationPage from './Pages/DestinationPage';

import HotelsPage from './Pages/HotelsPage';
import AboutUsPage from "./Pages/AboutUsPage";
import ContactUsPage from "./Pages/ContactUs";
import AdventuresPage from './Pages/AdventuresPage';
import KathmanduItinerary from './Component/KathmanduItinerary';
import KPCItinerary from './Component/KPCItinerary';
import KPItinerary from './Component/KPItinerary';
import KPMItinerary from './Component/KPMItinerary';
import KPNItinerary from './Component/KPNItinerary';
import KPCNItinerary from './Component/KPCNItinerary';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <NavBarCompnent />

        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/Tours' element={<DestinationPage />} />
          {/* <Route path='/Adventures' element={<AdventuresPage/>} /> */}
          <Route path='/Adventures' element={<AdventuresPage />} />
          <Route path='/Hotels' element={<HotelsPage />} />
          <Route path='/About' element={<AboutUsPage />} />
          <Route path='/Contact' element={<ContactUsPage />} />

          {/* /////////////////////////////////////////////////////////////////////////// */}

          {/* <Route path='/ToursInfo' element={<ToursInfo />} /> */}
          <Route path='/KItinerary' element={<KathmanduItinerary />} />
          <Route path='/KPItinerary' element={<KPItinerary/>} />
          <Route path='/KPCItinerary' element={<KPCItinerary />} />
          <Route path='/KPMItinerary' element={<KPMItinerary />} />
          <Route path='/KPNItinerary' element={<KPNItinerary />} />
          <Route path='/KPCNItinerary' element={<KPCNItinerary />} />
        </Routes>

      </BrowserRouter>
    </div>
  );
}

export default App;
