
import "../CSS/AdventuresPage.css"
import React from 'react'
import FooterComponent from "../Component/FooterComponent"

/// Images

import AviaFlight from "../Images/Avia-Flight-Pokhara1.png";
import BungyJump from "../Images/bunji-jumping.jpg";
import HimalayaTrekking from "../Images/Himalaya-Trekking1.jpg";
import MountainFlight from "../Images/mountain_flight_view.jpg";
import Paragliding from "../Images/Paraggliding-11.webp";
import RiverRafting from "../Images/River-Rafting-Nepal1.jpg";
import ZipFlyer from "../Images/Zip-flyer-Pokhara1.jpg";




const AdventuresPage = () => {

  const RightStyle = { borderTop: "2px solid gray", borderRight: "2px solid gray" }
  const LeftStyle = { borderTop: "2px solid gray", borderLeft: "2px solid gray" }
  return (
    <>
      <section className='Adventures-Section'>
        <div className='Adventures'>
          <div className='Adventures-Banner'>
          </div>
        </div>
      </section>

      {/* ///////////////////////////////////// section 2 //////////////////////////////////////////////// */}

      <section className="Adventures-Section-2">
        <div className="container">

          <div className="Adventures-title">
            <h1>Our Adventures</h1>
          </div>



          {/* first */}

          <div className="row">
            <div className="col-md-6 col-sm-12" style={RightStyle}>
              <div className="Adventure-1">
                <div className="AdventureImage-1">
                  <img src={AviaFlight} alt="" />
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="Adventure-info-1">
                <div className="Aventure-details-1">
                  <h4>Avia Flight in Pokhara</h4>
                  <hr></hr>
                  <p>Flights are from Pokhara In 15 minutes you will not miss the scenic beauty of both the Himalayas and the most
                    famous lake of Nepal, Phewa Tal. The flight will be a journey through nature. This is truly a unique experience.</p>
                </div>
              </div>
            </div>
          </div>

          {/* Second */}


          <div className="row">
            <div className="col-md-6 col-sm-12" style={LeftStyle}>
              <div className="Adventure-info-1">
                <div className="Aventure-details-1">
                  <h4>Bungy Jump in Pokhara</h4>
                  <hr></hr>
                  <p>Nepal’s first and only tower Bungee, HighGround Bungee & Swing has something for everyone.
                    Located at a convenient 20 minutes from Pokhara Lakeside, the jump site offers a spellbinding
                    view of the Himalayas, the hills and the rivers that will take your breath away..</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="Adventure-1">
                <div className="AdventureImage-1">
                  <img src={BungyJump} alt="" />
                </div>
              </div>
            </div>
          </div>


          {/* third */}


          <div className="row">
            <div className="col-md-6 col-sm-12" style={RightStyle}>
              <div className="Adventure-1">
                <div className="AdventureImage-1">
                  <img src={HimalayaTrekking} alt="" />
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="Adventure-info-1">
                <div className="Aventure-details-1">
                  <h4>Himalaya Trekking</h4>
                  <hr></hr>
                  <p>The summer monsoon is much heavier in the eastern Himalayas than it is in the west,
                    and so the most popular trekking periods in much of India, Nepal and the region east
                    of there are April and October. Skies tend to be clearer in the autumn, although it’s colder too,
                    but that’s when Everest and other popular treks are at their busiest.</p>
                </div>
              </div>
            </div>
          </div>


          {/* Fourth */}


          <div className="row">
            <div className="col-md-6 col-sm-12" style={LeftStyle}>
              <div className="Adventure-info-1">
                <div className="Aventure-details-1">
                  <h4>Mountain Flight in Kathmandu</h4>
                  <hr></hr>
                  <p>Mountain flights offer a panoramic view of the Himalaya in just one hour.
                    Kathmandu in the early morning for an hour’s worth of spectacular mountain scenery.
                    As the aircraft lifts up and heads towards the east, passengers don’t have to wait too
                    long to find out what’s in store for them.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="Adventure-1">
                <div className="AdventureImage-1">
                  <img src={MountainFlight} alt="" />
                </div>
              </div>
            </div>
          </div>


          {/* Fifth */}


          <div className="row">
            <div className="col-md-6 col-sm-12" style={RightStyle}>
              <div className="Adventure-1">
                <div className="AdventureImage-1">
                  <img src={Paragliding} alt="" />
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="Adventure-info-1">
                <div className="Aventure-details-1">
                  <h4>Paragliding – Pokhara</h4>
                  <hr></hr>
                  <p>Paragliding in Nepal can be a truly wonderful and fulfilling experience for the adventure seeker.
                    A trip will take you ov
                    er some of the best scenery on earth, as you share airspace with Himalayan griffin vultures, eagles,
                    kites and float over villages, monasteries, temples, lakes and jungle, with a fantastic view of the majestic Himalaya.</p>
                </div>
              </div>
            </div>
          </div>


          {/* Sixth */}


          <div className="row">
            <div className="col-md-6 col-sm-12" style={LeftStyle}>
              <div className="Adventure-info-1">
                <div className="Aventure-details-1">
                  <h4>River Rafting</h4>
                  <hr></hr>
                  <p>The scenery of river Trishuli includes small gorges and a glimpse of the cable car leading to the famous Hindu Temple Manakamana.
                    For the most of the year the rapids encountered on the Trisuli are straightforward, easily negotiated and well spaced out.
                    Trisuli river is an excellent river for those looking for a short river trip, without the challenge of huge rapids,
                    but with some really exciting rapids</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="Adventure-1">
                <div className="AdventureImage-1">
                  <img src={RiverRafting} alt="" />
                </div>
              </div>
            </div>
          </div>


          {/* Seven */}


          <div className="row">
            <div className="col-md-6 col-sm-12" style={RightStyle}>
              <div className="Adventure-1">
                <div className="AdventureImage-1">
                  <img src={ZipFlyer} alt="" />
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="Adventure-info-1">
                <div className="Aventure-details-1">
                  <h4>Zip Flyer Nepal – Zip Flyer Pokhara</h4>
                  <hr></hr>
                  <p>This awesome zipline launches from Sarangkot and drops 600m over 1.8km achieving a speed of 120km/h.
                    It is claimed to be the highest, longest and fastest zipline in the world. which give you a bird’s-eye view of the forest,
                    Mountains and surrounding scenery that can’t be viewed from the ground.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <FooterComponent />
    </>


  )
}

export default AdventuresPage