
import React, { useState } from 'react'
import '../CSS/DestinationPage.css'
import FooterComponent from '../Component/FooterComponent';
import { Link } from "react-router-dom"

// 3 logos 

import mountainlogo from "../Images/MountainLogo02.png"
import Walletlogo from "../Images/wallet.svg"
import Sutcaselogo from "../Images/suitcase.svg"

import DestinationList from '../Component/DestinationList';

// Images 

import Kathmandu from "../Images/kathmandu-Image001.JPG";
import pokhara from "../Images/Pokhara.JPG";
import chitwan from "../Images/chitwan-jungle-safari.jpg"
import muktinath from "../Images/muktinath-temple.png";
import bhktapur from "../Images/durbar-square-bhaktapur.jpg"
import nagarkot from "../Images/Nagarkot-Hotel.jpg"

const DestinationPage = () => {

  const [Destination, setDestination] = useState(DestinationList)


  // const dispatch = useDispatch();



  // const dlt = (_id) => {
  //     dispatch(DLT(_id))
  //     console.log(_id)
  // }
  return (
    <>

      <section className='Destination-Section'>
        <div className='Destination-banner-image'>
          <div className='Destination-Banner-text'>
          </div>
        </div>
      </section>


      {/* ///////////////////////////////// Section 2 /////////////////////////////////// */}

      <section className='Destination-Section_2'>
        <div className='container'>
          <div className='row'>


            <div className='col-md-4 col-sm-12'>
              <div className='Destination-Info'>

                <div className='row'>
                  <div className='col-12'>
                    <div className='section-2-picture'>
                      <img src={mountainlogo} alt="Mountain Logo" />
                    </div>
                  </div>

                  <div className='col-12'>
                    <div className='Section-2-info'>
                      <h3>
                        Top Destinations
                      </h3>
                      <p>Kathmandu , Pokhara , Chitwan , Muktinath, Nagarkot, Everest, Janakpur, Lumbini, Patan (Lalitpur), Bhaktapur, Sarangkot, Chandragiri Hills</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className='col-md-4 col-sm-12'>
              <div className='Destination-Info'>

                <div className='row'>
                  <div className='col-12'>
                    <div className='section-2-picture'>
                      <img src={Walletlogo} alt="Wallet" />
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className='Section-2-info'>
                      <h3>
                        The Best Prices
                      </h3>
                      <p>Best deals on Nepal tour packages. ... a wide range of customised vacation packages and explore all exciting tourist destinations in Nepal.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className='col-md-4 col-sm-12'>
              <div className='Destination-Info'>
                <div className='row'>
                  <div className='col-12'>
                    <div className='section-2-picture'>
                      <img src={Sutcaselogo} alt="SutcaseLogo" />
                    </div>
                  </div>

                  <div className='col-12'>
                    <div className='Section-2-info'>
                      <h3>
                        Amazing Services
                      </h3>
                      <p>To provide you with unmatched holiday experiences accompanied with best in class services and true value for your money.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>
          <hr></hr>
        </div>
      </section>

      {/* ///////////////////////////////// Section 3 /////////////////////////////////// */}



      <section className='Destination-Section-3'>
        <div className='container'>
          <div className='title'>
            <h1>Popular Tours</h1>
          </div>


          <div className='row'>
            
            <div className='col-lg-4 col-md-4 col-sm-12'>
              <Link to={`/KItinerary`}>
                <div className='Destination-card'>
                  <img src={Kathmandu} alt="Neapl Destination" />
                  <div className='card-facility'>
                    <div className='card-Name'>
                      <h2>Kathmandu</h2>
                      <p>04 Nights / 05 Days</p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <div className='col-lg-4 col-md-4 col-sm-12'>
              <Link to={`/KPItinerary`}>
                <div className='Destination-card'>
                  <img src={pokhara} alt="Neapl Destination" />
                  <div className='card-facility'>
                    <div className='card-Name'>
                      <h2>Kathmandu + Pokhara</h2>
                      <p>05 Nights / 06 Days</p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <div className='col-lg-4 col-md-4 col-sm-12'>
              <Link to={`/KPCItinerary`}>
                <div className='Destination-card'>
                  <img src={chitwan} alt="Neapl Destination" />
                  <div className='card-facility'>
                    <div className='card-Name'>
                      <h2>Kathmandu + Pokhara + Chitwan</h2>
                      <p>07 Nights / 08 Days</p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <div className='col-lg-4 col-md-4 col-sm-12'>
              <Link to={`/KPMItinerary`}>
                <div className='Destination-card'>
                  <img src={muktinath} alt="Neapl Destination" />
                  <div className='card-facility'>
                    <div className='card-Name'>
                      <h2>Kathmandu + Pokhara + Muktinath</h2>
                      <p>07 Nights / 08 Days</p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <div className='col-lg-4 col-md-4 col-sm-12'>
              <Link to={`/KPNItinerary`}>
                <div className='Destination-card'>
                  <img src={bhktapur} alt="Neapl Destination" />
                  <div className='card-facility'>
                    <div className='card-Name'>
                      <h2>Kathmandu + Pokhara + Nagarkot</h2>
                      <p>06 Nights / 07 Days</p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <div className='col-lg-4 col-md-4 col-sm-12'>
              <Link to={`/KPCNItinerary`}>
                <div className='Destination-card'>
                  <img src={nagarkot} alt="Neapl Destination" />
                  <div className='card-facility'>
                    <div className='card-Name'>
                      <h2>Kathmandu + Pokhara + Chitwan + Nagarkot</h2>
                      <p>08 Nights / 09 Days</p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>





          </div>

        </div>

      </section>

      <FooterComponent />
    </>
  )
}

export default DestinationPage