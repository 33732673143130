
import React, { useState } from 'react';
import "../CSS/HotelPage.css";
import FooterComponent from '../Component/FooterComponent';

import HotelList from '../Component/HotelList';
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';




const HotelsPage = () => {

  const [hotels, setHotels] = useState(HotelList)
  return (
    <>
      <section className='Hotelpage-Section'>
        <div className='Hotel-banner-image'>
          <div className='Hotel-Banner-text'>
          </div>
        </div>
      </section>

      {/* ///////////////////////////////// Section 2 /////////////////////////////////// */}


      <section className='Hotel-List'>
        <div className='container'>
          <div className='Hotel-text'>
            <h4>
              Hotels
            </h4>
          </div>

          {
            hotels.map((elem) => {
              const { HotelName, HotelDetails,Image } = elem; 

              return (
                <>
                  <div className='Hotel-body'>
                    <div className='row'>
                      <div className='col-md-4'>
                        <Fade left>
                          <div className='Hotel-Images'>
                            <img src={Image} alt='Nepal Hotel Images' />
                          </div>
                        </Fade>
                      </div>
                      <div className='col-md-8'>
                        <Flip left>
                          <div className='Hotel-details'>
                            <div className='Hotel-Info'>
                              <h4>{HotelName}</h4>
                              <hr></hr>
                              <p>{HotelDetails}</p>
                            </div>
                          </div>
                        </Flip>
                      </div>
                    </div>
                  </div>
                  <hr></hr>
                </>
              )
            })
          }

        </div>

      </section>

      <FooterComponent />
    </>
  )
}

export default HotelsPage