import React from 'react'
import "../CSS/Footer.css"
import { Link } from 'react-router-dom'

const FooterComponent = () => {
    return (
        <>
            {/* <!-- ===================================== Footer Section ====================== --> */}
            <div class="footer-basic">
                <footer>
                    {/* <!-- <div class="social">
                        <a href="https://www.instagram.com/manju_kitchen_rajasthani"><i class="fa fa-instagram"></i></a>
                        <a href="https://www.youtube.com/channel/UC8dapJDJQB4iq8IA8MZlbdg"><i class="fa fa-youtube"></i></a>
                        <a href="https://twitter.com/manjuskitchen"><i class="fa fa-twitter"></i></a>
                        <a href="https://www.facebook.com/manjuskitchenrajasthan"><i class="fa fa-facebook"></i></a>
                    </div> --> */}
                    <ul class="list-inline">
                        <li class="list-inline-item"><Link to="/">Home</Link></li>
                        <li class="list-inline-item"><Link to="/Tours">Tours</Link></li>
                        <li class="list-inline-item"><Link to="/Adventures">Adventures</Link></li>
                        <li class="list-inline-item"><Link to="/Hotels">Hotels</Link></li>
                        <li class="list-inline-item"><Link to="/About">About Us </Link></li>
                        <li class="list-inline-item"><Link to="/Contact">Contact Us</Link></li>
                    </ul>
              
                <p class="copyright">© Copyright Nepal Tours. All Rights Reserved</p>
                <p class="copyright"><a href="https://skdm.in/">Designed by <strong>Shree Krishna Digital Marketing</strong></a></p>
            </footer>
        </div>
        </>
    )
}

export default FooterComponent;